import type { ObjectId } from 'bson'
import BaseModel, { ModelRelationType } from './base'
import type { _BaseType } from './_base_type'
import { DeliveryZoneModel, type DeliveryZoneType } from './deliveryZone'

export type StateType = _BaseType & {
  code: string
  countryCode: string
  name: string
  status: number

  zones: ObjectId[]
  zoneRecords?: DeliveryZoneType[]
}

export class StateModel extends BaseModel {
  constructor() {
    super('states')
    this.uniques = [['countryCode', 'code']]
    this.required = ['countryCode', 'code', 'name', 'status']
  }

  setRelation() {
    this.relation.zoneRecords = {
      type: ModelRelationType.BelongsTo,
      model: new DeliveryZoneModel(),
      foreignKey: 'zones',
      targetKey: '_id',
    }
  }
}
