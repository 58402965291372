import BaseModel, { ModelRelationType } from './base'
import { StateModel, type StateType } from './state'
import type { _BaseType } from './_base_type'

export type DeliveryZoneType = _BaseType & {
  name: string
  status: number

  states?: StateType[]
}
export class DeliveryZoneModel extends BaseModel {
  constructor() {
    super('deliveryZones')
    this.uniques = [['name']]
    this.required = ['name', 'status']
  }

  setRelation() {
    this.relation.states = {
      type: ModelRelationType.HasMany,
      model: new StateModel(),
      foreignKey: '_id',
      targetKey: 'zones',
    }
  }
}
